import consumer from "./consumer";
import { get } from "@rails/request.js";
import Rails from "@rails/ujs";

$(document).on("asset:requested", function() {
  $("[data-attachable-id][data-attachable-type][data-requested]").each(function() {
    const $attachable = $(this);

    consumer.subscriptions.create({
      channel: "AttachableChannel",
      id: $attachable.data("attachable-id"),
      type: $attachable.data("attachable-type")
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel

        // Trigger a request to (re-)load the assets for an attachable displayed on screen
        Rails.ajax({ type: "GET", url: $attachable.data("path"), dataType: "script" });

        // Download the requested asset
        if (data.asset_path) {
          this.downloadAsset(data.asset_path, /* retry */ true);
        }
      },

      // private

      async downloadAsset(path, retry) {
        const response = await get(path);
        if (response.ok) { // if the file exists on S3
          window.location.replace(path); // trigger the file download
        } else if (response.statusCode === 404) { // if the file doesn't exist on S3
          if (retry) {
            // wait 2 second whilst the file is uploaded to s3
            await new Promise(resolve => setTimeout(resolve, 2000));
            // re-attempt the download, allowing the 404 if it fails this time
            this.downloadAsset(path, /* retry */ false);
          } else {
            window.location.replace("/404"); // display a 404
          }
        }
      }
    });
  });
});
