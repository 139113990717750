/* eslint-disable import/first */
// Rails related imports
import Rails from "@rails/ujs";
window.Rails = Rails;
Rails.start();

import Turbolinks from "turbolinks";
Turbolinks.start();

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "trix";
import "@rails/actiontext";

// Import jQuery _before_ the 'stimulus controllers',
// 'actioncable channels' and the other 3rd party libraries.

import "jquery";

import "./channels";
import "./controllers";
import "./libraries";

// Our custom javascript
import "./global.coffee";
import "./components";
import "./models";
/* eslint-enable import/first */
