import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table", "panel", "dropdownMenu"];
  static values = {
    breakpoint: Number // TODO: Add default when upgrading stimulus
  };

  connect() {
    this.setWidth();
  }

  // Called when resizing the screen
  setWidth() {
    this.setResponsiveElements(this.width());
  }

  // TODO: this will not work until we upgrade to stimulus v3
  // Called when tables are dynamically added to the screen
  tableTargetConnected(element) {
    this.setElementWidth(element, this.width());
  }

  // TODO: this will not work until we upgrade to stimulus v3
  // Called when panels are dynamically added to the screen
  panelTargetConnected(element) {
    this.setElementWidth(element, this.width());
  }

  // TODO: this will not work until we upgrade to stimulus v3
  // Called when dropdowns are dynamically added to the screen
  dropdownMenuTargetConnected(element) {
    this.setElementWidth(element, this.width());
  }

  // private

  setResponsiveElements(width) {
    this.tableTargets.forEach(table => {
      this.setElementWidth(table, width);
    });
    this.panelTargets.forEach(panel => {
      this.setElementWidth(panel, width);
    });
    this.dropdownMenuTargets.forEach(dropdownMenu => {
      this.setElementWidth(dropdownMenu, width);
    });
  }

  width() {
    const windowWidth = $(window).width();
    return (windowWidth < this.breakpointValue) ? `${windowWidth - 30}px` : "auto";
  }

  setElementWidth(element, width) {
    element.style.width = width;
  }
}
