import consumer from "./consumer";

$(document).on("page:show", function() {
  $("#new_terms_and_conditions_form .generating:not(.hidden)").each(function() {
    consumer.subscriptions.create({
      channel: "OnboardingChannel",
      rate_card_id: $(this).data("rate-card-id"),
      assignment_id: $(this).data("assignment-id")
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        const path = $("#new_terms_and_conditions_form .generating").data("success-path");
        if (data.success && path) {
          window.location.href = path;
        }
      }
    });
  });
});
